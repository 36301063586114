@tailwind base;
@tailwind components;
@tailwind utilities;


h1 {
  font-size: 30px;
  font-weight: bold;
}

h2{
    font-size: 20px;
    font-weight: 600;
}

h3{
    font-size: 19px;
}