.hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* WebKit */
  }

  /* Loader animation CSSS */
  @keyframes ping {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .animate-ping {
    animation: ping 0.1s linear infinite;  
  }
  
  